<template>
  <div>
    <!-- <div class="exam-content-section" v-else-if="displayTab === 'billing'"> -->
    <div class="exam-content-section">
      <div class="row">
        <div class="col-9">
          <div class="exam-description">
            <div class="header-description">
              <h1>Billing</h1>
              <p>
                During school year (SY) 2012-2013, the Bureau of Indian
                Education (BIE) continued its efforts to improve the validity
                and reliability of data reporting.
              </p>
            </div>

            <div class="exam-marks">
              <div class="exam-partitions">
                <div class="exam-scores">
                  <h1>4,000.00</h1>
                  <h2>Total Dues</h2>
                </div>

                <div class="exam-scores">
                  <h1>2,000.00</h1>
                  <h2>Total Advance</h2>
                </div>

                <div class="exam-scores">
                  <h1>8,000.00</h1>
                  <h2>Total Payment</h2>
                </div>

                <div class="exam-scores">
                  <h1>12,000.00</h1>
                  <h2>Full Marks</h2>
                </div>
              </div>

              <!-- <div class="exam-term">
                          <a href="">
                            <h1>First Term Exam</h1>
                          </a>
                        </div> -->
            </div>

            <div class="analysis-status">
              <div class="download-status">
                <h1><a href="">Download Statement</a></h1>
                <h1><a href="">Download Bill Generate</a></h1>
                <h1><a href="">Download Fee Payment</a></h1>
              </div>

              <div class="exam-analysis">
                <a href="">
                  <h1>Overall Analysis</h1>
                </a>
              </div>
            </div>
          </div>

          <div class="scored-marks">
            <div class="scored-marks-header">
              <v-container fluid>
                <v-row align="center">
                  <v-col cols="6">
                    <!-- <v-select
                                v-model="select"
                                :hint="`${select.state}, ${select.abbr}`"
                                :items="items"
                                item-text="state"
                                item-value="abbr"
                                label="Select"
                                persistent-hint
                                return-object
                                single-line
                              ></v-select> -->
                    <v-select
                      outlined
                      dense
                      :items="types"
                      required
                      class="pa-0"
                      label="Select Billing"
                      v-model="selectType"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </div>

            <div class="performance-analysis">
              <div class="billing-marks-table" v-if="selectType == 'dues'">
                <!-- <h1>Dues Report</h1> -->
                <table class="billing-table" style="width: 100%">
                  <tr>
                    <th style="width: 4%">S.N</th>
                    <th style="width: 15%">Name</th>
                    <th style="width: 14%">Enroll Code</th>
                    <th style="width: 8%">Grade</th>
                    <th style="width: 14%">Guardian Details</th>
                    <th style="width: 12%">Uploaded Due</th>
                    <th style="width: 10%">Collected Due</th>
                    <th style="width: 10%">Remaining Due Amount</th>
                  </tr>

                  <tr>
                    <td>1.</td>
                    <td style="text-align: left !important">Aaieshma Kafle</td>
                    <td>PSB-770045</td>
                    <td>9'A'</td>
                    <td>Yam Lal Kafle</td>
                    <td>400.00</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>

                  <tr>
                    <td>2.</td>
                    <td style="text-align: left !important">Akira Thapa</td>
                    <td>30</td>
                    <td>B</td>
                    <td>-</td>
                    <td>2.8</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>

                  <!-- <tr>
                              <td>3.</td>
                              <td>Mathematics</td>
                              <td>30</td>
                              <td>B+</td>
                              <td>-</td>
                              <td>3.2</td>
                              <td>0</td>
                              <td>0</td>
                            </tr>

                            <tr>
                              <td>4.</td>
                              <td>Science</td>
                              <td>30</td>
                              <td>C</td>
                              <td>-</td>
                              <td>2</td>
                              <td>0</td>
                              <td>0</td>
                            </tr>

                            <tr>
                              <td>5.</td>
                              <td>Social Studies</td>
                              <td>30</td>
                              <td>B</td>
                              <td>-</td>
                              <td>2.8</td>
                              <td>0</td>
                              <td>0</td>
                            </tr>

                            <tr>
                              <td>6.</td>
                              <td>General Knowledge</td>
                              <td>30</td>
                              <td>A+</td>
                              <td>-</td>
                              <td>4</td>
                              <td>0</td>
                              <td>0</td>
                            </tr>

                            <tr>
                              <td>7.</td>
                              <td>Conversation</td>
                              <td>30</td>
                              <td>A</td>
                              <td>-</td>
                              <td>3.6</td>
                              <td>0</td>
                              <td>0</td>
                            </tr>

                            <tr>
                              <td>8.</td>
                              <td>Handwriting</td>
                              <td>30</td>
                              <td>B</td>
                              <td>-</td>
                              <td>2.8</td>
                              <td>0</td>
                              <td>0</td>
                            </tr>

                            <tr>
                              <td>9.</td>
                              <td>Life Skill</td>
                              <td>30</td>
                              <td>B</td>
                              <td>-</td>
                              <td>2.8</td>
                              <td>0</td>
                              <td>0</td>
                            </tr> -->
                </table>
              </div>
            </div>
          </div>

          <div class="transport-section" v-if="selectType == 'trs'">
            <div class="row">
              <div class="col-5">
                <div class="transport-location">
                  <div class="transport-content">
                    <h1>Route</h1>
                    <span class="material-icons"> chevron_right </span>

                    <h1>School to Sota</h1>
                  </div>

                  <div class="transport-content">
                    <h1>Destination</h1>
                    <span class="material-icons"> chevron_right </span>

                    <h1>Pulkhamukha</h1>
                  </div>

                  <div class="transport-content">
                    <h1>Vehicle</h1>
                    <span class="material-icons"> chevron_right </span>

                    <h1>School Van</h1>
                  </div>

                  <div class="transport-content">
                    <h1>Fee</h1>
                    <span class="material-icons"> chevron_right </span>

                    <h1>800 <span>Per Month</span></h1>
                  </div>

                  <div class="transport-content">
                    <h1>Discount</h1>
                    <span class="material-icons"> chevron_right </span>

                    <h1>200</h1>
                  </div>
                </div>
              </div>

              <div class="offset-2 col-5">
                <div class="transport-info">
                  <div class="vehicle-info">
                    <h1>Ramesh Bhattrai</h1>
                    <p>School Van</p>
                    <h2>Lu 1 Ja 1964</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="statement-section" v-if="selectType == 'stm'">
            <div class="statement-content">
              <h1>Statement Details</h1>
              <div class="statement-info">
                <h1>Monday, August 21, 2022</h1>
                <p>Total for day : 12 hrs 13 mins</p>

                <table class="statement-table" style="width: 100%">
                  <tr>
                    <th style="width: 6%">S.N</th>
                    <th style="width: 14%">Date</th>
                    <th>Via</th>
                    <th>Bill Amount</th>
                    <th style="width: 14%">Paid Amount</th>
                    <th>Instant Discount</th>
                    <th>Remarks</th>
                  </tr>

                  <tr>
                    <td>1.</td>
                    <td style="text-align: left !important">2078-01-12</td>
                    <td>Previous Due</td>
                    <td>(Rs. 400.00)</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>

                  <tr>
                    <td>1.</td>
                    <td style="text-align: left !important">2078-01-12</td>
                    <td>Previous Due</td>
                    <td>(Rs. 400.00)</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>

                  <tr>
                    <td>1.</td>
                    <td style="text-align: left !important">2078-01-12</td>
                    <td>Previous Due</td>
                    <td>(Rs. 400.00)</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                </table>

                <h3>Remaining Due = Rs.400.00 ( Four Hundred Only/-)</h3>
              </div>
            </div>
          </div>
        </div>

        <div class="col-3 pl-0 pr-0">
          <div class="exam-diagram">
            <h1>STUDENTS SCORED MARKS OVERVIEW</h1>
            <DonutChart></DonutChart>
            <!-- <div id="chart">
              <apexchart
                type="donut"
                :options="chartOptions"
                :series="series"
              ></apexchart>
            </div> -->

            <br />
            <br />

            <div id="chart">
              <apexchart
                type="bar"
                :options="chartOptions"
                :series="series"
              ></apexchart>
            </div>

            <!-- <app-root></app-root> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    selectType: "dues",
    types: [
      {
        text: "Dues Report",
        value: "dues",
      },
      {
        text: "Transport",
        value: "trs",
      },
      {
        text: "Statement",
        value: "stm",
      },
    ],


    displayTab: "billing",
    dueReport: "",

    series: [44, 55, 41, 17, 15],
    series: [
      {
        data: [44, 55, 41, 17, 15],
        data: [21, 22, 10, 28, 16, 21, 13, 30],
      },
    ],
    chartOptions: {
      chart: {
        width: "100%",
        type: "donut",
      },
      dataLabels: {
        enabled: false,
      },

      legend: {
        position: "bottom",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            abels: {
              show: false,
            },
          },
        },
      ],
    },
  }),
};
</script>
<style lang="scss">
</style>